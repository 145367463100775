import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringJoin'
})
export class StringJoinPipe implements PipeTransform {
    transform(input: Array<any>, sep = ','): string {
        if (!Array.isArray(input)) {
            return input;
        }
        return input.join(sep);
    }
}
