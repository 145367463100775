import { DialogResult } from '../enums';
import { KeyValuePair } from '@brokerportal/common/enums';

// https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
export function mergeArray<T>(target: Array<T>, source: Array<T>, uniqueKeyName: string) {
    const mergedDict: { [key: string]: T } = {};

    [...target, ...source].forEach(_ => {
        mergedDict[_[uniqueKeyName]] = _;
    });

    return Object.keys(mergedDict).map(key => mergedDict[key]);
}

export function isEmpty(str) {
    return !str || 0 === str.length;
}

export const isNullOrUndefined = (object: any): object is undefined => object === null || object === undefined;

export function parseFilenameFromContentDisposition(contentDisposition: string) {
    if (!contentDisposition) {
        return null;
    }

    let filename = '';
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
}

export function isDialogResult(value: any): value is DialogResult {
    return Object.values(DialogResult).includes(value);
}

export function isEmptyInputValue(value: any): boolean {
    /**
     * Check if the object is a string or array before evaluating the length attribute.
     * This avoids falsely rejecting objects that contain a custom length attribute.
     * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
     */
    return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

export const toQueryString = (queryObj: {}) => {
    return Object.keys(queryObj)
        .filter(k => !isNullOrUndefined(queryObj[k]))
        .map(k => `${k}=${encodeURIComponent(queryObj[k])}`)
        .join('&');
};

export const pascalToSentence = value => {
    const sentence = value.replace(/([A-Z])/g, ' $1').trim();
    return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
};

export const fromDictToKeyValuePairs = (obj: { [key: string]: any }): KeyValuePair[] => {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
};

export const EnumHelper = {
    parse: <T>(enumType: T, val: string) => enumType[val as keyof T]
};
