<div class="sidenav-container" fxLayout="column">
    <mat-toolbar color="primary" class="sidenav-toolbar mat-elevation-z2">
        <img [src]="env.constants.branding.images.logoUrl" fxFlexAlign="center" class="fade-in-on-icon-sidenav" style="max-height: 30px;" />
    </mat-toolbar>
    <div *ngIf="items.length > 0" class="sidenav-scrollbar-container" fxLayout="column">
        <bp-scrollbar>
            <mat-nav-list fxLayout="column" class="sidenav-list sidenav-toplevel">
                <bp-sidenav-item *ngFor="let item of items" [item]="item"></bp-sidenav-item>
                <!-- Add placeholder to ensure scrolls above chat box (zsiqwidget) -->
                <div class="sidenav-placeholder-item"></div>
                <div class="sidenav-placeholder-item"></div>
            </mat-nav-list>
        </bp-scrollbar>
    </div>
    <div *ngIf="items.length === 0" fxLayout="column">
        <div class="sidenav-placeholder-wrapper" *ngFor="let p of placeholderItems">
            <div class="sidenav-placeholder">
                <div class="animated-background">
                    <div class="background-masker header-top"></div>
                    <div class="background-masker header-left"></div>
                    <div class="background-masker header-right"></div>
                    <div class="background-masker header-bottom"></div>
                    <div class="background-masker subheader-left"></div>
                    <div class="background-masker subheader-right"></div>
                    <div class="background-masker subheader-bottom"></div>
                </div>
            </div>
        </div>
    </div>
</div>
