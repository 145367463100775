import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { map, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '@brokerportal/common/auth/authentication.service';
import { FeatureFlagService } from '@brokerportal/common/services';
import { BrokerPortalFeatureCodes } from '@brokerportal/common/enums';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private featureFlagService: FeatureFlagService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (environment.maintenanceMode) {
            return this.router.parseUrl('/maintenance');
        }

        if (environment.bypassAuthentication) {
            return true;
        }

        return this.auth.isAuthenticated().pipe(
            switchMap(isAuthenticated => {
                if (isAuthenticated) {
                    return [true];
                }

                let returnUrl = '';
                if (state && state.url && state.url !== '/') {
                    returnUrl = `?returnUrl=${state.url}`;
                }

                return this.featureFlagService.isEnabled(BrokerPortalFeatureCodes.EnableFusionAuth).pipe(
                    map(isFusionAuthEnabled => {
                        const url = isFusionAuthEnabled
                            ? `/authentication/login-auth${returnUrl}`
                            : `/authentication/login${returnUrl}`;
                        return this.router.parseUrl(url);
                    })
                );
            })
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}
