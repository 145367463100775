import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import * as auth from './auth';
import * as guards from './guards';
import * as directives from './directives';
import * as pipes from './pipes';
import * as services from './services';
import * as validators from './validators';
import { CookieService } from 'ngx-cookie-service';

const Guards = [Object.values(guards)];

@NgModule({
    imports: [CommonModule],
    declarations: [
        pipes.AmDateFormatPipe,
        pipes.ApprovalConditionStatusDescriptionPipe,
        pipes.ConvertTimezonePipe,
        pipes.CreditStatusDescriptionPipe,
        pipes.ConditionDescriptionPipe,
        pipes.EmploymentTypeDescriptionPipe,
        pipes.FirstErrorPipe,
        pipes.HomeOwnershipDescriptionPipe,
        pipes.IdentityStatusDescriptionPipe,
        pipes.IdentityStatusExplanationPipe,
        pipes.LoanConditionStatusDescriptionPipe,
        pipes.LoanPurposeDescriptionPipe,
        pipes.PascalToSentenceCasePipe,
        pipes.ProductTagDescriptionFromStringPipe,
        pipes.ProductTagDescriptionPipe,
        pipes.QuoteScopeFormatPipe,
        pipes.SafePipe,
        pipes.TimezoneFormat,
        pipes.EmptyPipe,
        pipes.NameJoiningPipe,
        pipes.ResidencyStatusPipe,
        directives.ClickOutsideDirective,
        directives.FeatureFlagDirective,
        directives.NewlineDelimitedInputDirective,
        auth.HasDemandDirective,
        auth.HasDemandDirectiveStructural,
        pipes.PropertyOwnershipStatusPipe,
        pipes.ReadableTimeElapsedSinceDatePipe,
        pipes.SafeHtmlPipe,
        pipes.LoanDrawerChannelDescriptionPipe,
        pipes.ExternalReferenceNumberTypeDescriptionPipe,
        pipes.IndefiniteExternalReferenceNumberTypeDescriptionPipe,
        pipes.FirstCharUppercasePipe,
        pipes.CustomerNamePipe,
        pipes.RiskTierDescriptionPipe,
        pipes.StringJoinPipe
    ],
    providers: [
        services.HarmonyClient,
        services.ApplicationLinksService,
        services.CreditStatusService,
        services.FeatureFlagService,
        services.MailService,
        services.MediaReplayService,
        services.PortalConfigurationService,
        services.BrokerSettingService,
        services.WorkflowManifestService,
        services.CachedService,
        services.TitleService,
        services.ProductQuoteService,
        services.CustomerService,
        services.QuoteService,
        services.QuoteMockService,
        services.ErrorCallbackGeneratorService,
        services.RatesService,
        services.FieldMappingService,
        services.PortalActionsService,
        services.TieredOriginationService,
        services.ExtraFieldsService,
        services.IdentityService,
        services.DocumentInformationService,
        auth.AuthenticationService,
        services.CurrencyService,
        auth.AuthGuard,
        pipes.AmDateFormatPipe,
        pipes.ApprovalConditionStatusDescriptionPipe,
        pipes.ConvertTimezonePipe,
        pipes.CreditStatusDescriptionPipe,
        pipes.ConditionDescriptionPipe,
        pipes.EmploymentTypeDescriptionPipe,
        pipes.FirstErrorPipe,
        pipes.HomeOwnershipDescriptionPipe,
        pipes.IdentityStatusDescriptionPipe,
        pipes.IdentityStatusExplanationPipe,
        pipes.LoanConditionStatusDescriptionPipe,
        pipes.LoanPurposeDescriptionPipe,
        pipes.PascalToSentenceCasePipe,
        pipes.ProductTagDescriptionFromStringPipe,
        pipes.ProductTagDescriptionPipe,
        pipes.QuoteScopeFormatPipe,
        pipes.SafePipe,
        pipes.TimezoneFormat,
        pipes.EmptyPipe,
        pipes.NameJoiningPipe,
        pipes.ResidencyStatusPipe,
        directives.DEFAULT_NEWLINE_DELIMITED_INPUT_OPTIONS_PROVIDER,
        CookieService,
        pipes.PropertyOwnershipStatusPipe,
        pipes.ReadableTimeElapsedSinceDatePipe,
        pipes.SafeHtmlPipe,
        pipes.LoanDrawerChannelDescriptionPipe,
        pipes.ExternalReferenceNumberTypeDescriptionPipe,
        pipes.IndefiniteExternalReferenceNumberTypeDescriptionPipe,
        pipes.FirstCharUppercasePipe,
        pipes.CustomerNamePipe,
        validators.ValidatorOrchestrator,
        Guards,
        pipes.RiskTierDescriptionPipe,
        pipes.StringJoinPipe
    ],
    exports: [
        pipes.AmDateFormatPipe,
        pipes.ApprovalConditionStatusDescriptionPipe,
        pipes.ConvertTimezonePipe,
        pipes.CreditStatusDescriptionPipe,
        pipes.ConditionDescriptionPipe,
        pipes.EmploymentTypeDescriptionPipe,
        pipes.FirstErrorPipe,
        pipes.HomeOwnershipDescriptionPipe,
        pipes.IdentityStatusDescriptionPipe,
        pipes.IdentityStatusExplanationPipe,
        pipes.LoanConditionStatusDescriptionPipe,
        pipes.LoanPurposeDescriptionPipe,
        pipes.PascalToSentenceCasePipe,
        pipes.ProductTagDescriptionFromStringPipe,
        pipes.ProductTagDescriptionPipe,
        pipes.QuoteScopeFormatPipe,
        pipes.SafePipe,
        pipes.TimezoneFormat,
        pipes.EmptyPipe,
        pipes.NameJoiningPipe,
        pipes.ResidencyStatusPipe,
        directives.ClickOutsideDirective,
        directives.FeatureFlagDirective,
        directives.NewlineDelimitedInputDirective,
        auth.HasDemandDirective,
        auth.HasDemandDirectiveStructural,
        pipes.PropertyOwnershipStatusPipe,
        pipes.ReadableTimeElapsedSinceDatePipe,
        pipes.SafeHtmlPipe,
        pipes.LoanDrawerChannelDescriptionPipe,
        pipes.ExternalReferenceNumberTypeDescriptionPipe,
        pipes.IndefiniteExternalReferenceNumberTypeDescriptionPipe,
        pipes.FirstCharUppercasePipe,
        pipes.CustomerNamePipe,
        pipes.RiskTierDescriptionPipe,
        pipes.StringJoinPipe
    ]
})
export class CommonUiModule {}
