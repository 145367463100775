import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './ui/admin/components';
import { AuthGuard } from '@brokerportal/common/auth';

const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/home/home.module').then(m => m.HomeUiModule)
            },
            {
                path: 'application',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/application/applications.module').then(m => m.ApplicationsUiModule)
            },
            {
                path: 'applications',
                canActivateChild: [AuthGuard],
                loadChildren: () =>
                    import('./ui/application/reports/application-reports.module').then(
                        m => m.ApplicationReportingUiModule
                    )
            },
            {
                path: 'referral',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/referral/referral.module').then(m => m.ReferralUiModule)
            },
            {
                path: 'reporting',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/reporting/reporting.module').then(m => m.ReportingUiModule)
            },
            {
                path: 'library',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/library/library.module').then(m => m.LibraryUiModule)
            },
            {
                path: 'tools',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/tools/tools.module').then(m => m.ToolsUiModule)
            },
            {
                path: 'settings',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/settings/settings.module').then(m => m.SettingsUiModule)
            },
            {
                path: 'contact',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/contact/contact.module').then(m => m.ContactUiModule)
            },
            {
                path: 'quotes',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/quote/quote.module').then(m => m.QuoteUiModule)
            },
            {
                path: 'nsw-quotes',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/quote/quote-nsw.module').then(m => m.QuoteNswUiModule)
            },
            {
                path: 'sapn-quotes',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/quote/quote-sapn.module').then(m => m.QuoteSapnUiModule)
            },
            {
                path: 'nsw-quotes-eaas',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/quote/quote-nsw-eaas.module').then(m => m.QuoteNswEaasUiModule)
            },
            {
                path: 'quotes-eaas',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/quote/quote-sa-eaas.module').then(m => m.QuoteSaEaasUiModule)
            },
            {
                path: 'policy',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/policy/policy.module').then(m => m.PolicyUiModule)
            },
            {
                path: 'greenconnect',
                canActivateChild: [AuthGuard],
                loadChildren: () =>
                    import('./ui/renewable-marketplace/renewable-marketplace.module').then(
                        m => m.RenewableMarketPlaceUiModule
                    )
            },
            {
                path: 'referrals',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./ui/referrals/referrals.module').then(m => m.ReferralsUiModule)
            }
        ]
    },
    {
        path: 'authentication',
        loadChildren: () => import('./ui/authentication/authentication.module').then(m => m.AuthenticationUiModule)
    },
    {
        path: '**',
        redirectTo: 'authentication'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule],
    providers: []
})
export class RoutingModule {}
