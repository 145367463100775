import { Scheme } from './models';
import { environment } from 'environments/environment';

export class TemplateText {
    scope: Scheme;
    deposit: string;
    Deposit: string;
    EligibleBattery: string;
    EligibleBatteryPrice: string;
    EligibleEquipment: string;
    eligibleEquipment: string;
    EligiblePanel: string;
    EligiblePanelPrice: string;
    gstStatus: string;
    gstInclusive: string;
    phone: string;
    email: string;
    schemeProduct: string;
    schemeName: string;
    depositPayTime: string;

    constructor(scope: Scheme, isGstInc = true) {
        this.scope = scope;
        this.deposit = this.isNsw() ? 'payment' : 'deposit';
        this.Deposit = this.isNsw() ? 'Payment' : 'Deposit';
        this.EligibleBattery = `Eligible ${this.isNsw() || this.isSapn() ? 'Battery ' : ''}System`;
        this.EligibleBatteryPrice = `Eligible ${this.isNsw() || this.isSapn() ? 'battery ' : ''}system price ${
            isGstInc ? '(GST inclusive)' : ''
        }`;
        this.EligiblePanelPrice = `${this.isNsw() ? 'Eligible solar system' : 'Solar panel '} price ${
            isGstInc ? '(GST inclusive)' : ''
        }`;
        this.gstStatus = isGstInc ? 'inclusive' : 'exclusive';
        this.gstInclusive = isGstInc ? ' (GST inclusive)' : '';
        this.phone = this.isNsw() ? '1300 645 015' : '1300 396 078';
        this.email = this.getEmail();
        this.eligibleEquipment = this.isNsw() ? 'eligible equipment' : 'eligible system';
        this.EligibleEquipment = this.isNsw() ? 'Eligible Equipment' : 'Eligible System';
        this.schemeProduct = this.isNsw() ? 'loan' : 'subsidy';
        this.schemeName = this.isNsw() ? 'Empowering Homes program' : 'Home Battery Scheme';
        this.depositPayTime = this.isNsw() ? 'prior to or after installation' : 'prior to installation';
    }

    private isNsw() {
        return this.scope === Scheme.NewSouthWales;
    }

    private isSapn() {
        return this.scope === Scheme.SouthAustraliaPowerNetworks;
    }

    private getEmail() {
        switch (this.scope) {
            case Scheme.NewSouthWales:
                return environment.constants.branding.contact.contactEHPEmail;
            case Scheme.SouthAustraliaPowerNetworks:
                return environment.constants.branding.contact.contactEnergyMasterEmail;
            default:
                return environment.constants.branding.contact.contactHBSEmail;
        }
    }
}
