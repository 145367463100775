<bp-page-header
    [title]="headerTitle()"
    subTitle="{{ isCrudViewMode() || isCrudEditMode() ? 'Quote Record number: ' + quoteNumber : '' }}"
></bp-page-header>
<section class="dashboard-container">
    <p *ngIf="(isCrudCreateMode() || isCrudEditMode()) && isNswScheme()">
        The NSW Empowering Homes Program offers interest-free loans up to $14,000 for up to 8 years for the purchase of
        qualifying solar battery systems, and up to $9,000 for up to 10 years for the purchase of qualifying battery
        systems
    </p>
    <p *ngIf="!isCrudCreateMode() && !(quoteStatusService.isEditable(quoteStatus) || isNewBuildEditable())">
        This Quote Record cannot be edited {{ quoteStatusService.notEditableReasonText(quoteStatus) }}
    </p>
    <form [formGroup]="quoteForm" (ngSubmit)="onSubmit(quoteForm.value)">
        <section
            *ngIf="
                isConfirmable() ||
                quoteStatusService.isEditable(quoteStatus) ||
                (quoteStatusService.isCancelable(quoteStatus) && (isCrudViewMode() || isCrudEditMode()))
            "
        >
            <ng-container *ngTemplateOutlet="quoteToolbar"></ng-container>
            <br />
        </section>

        <section *ngIf="(hasErrorsOnSubmit && !quoteForm.valid) || hasExternalErrors()" data-cy="section.errorSummary">
            <mat-card fxLayout="column">
                <mat-card-subtitle>
                    There were some issues {{ isCrudEditMode() ? 'editing' : 'processing' }} your Quote Record. Please
                    resolve the following before continuing:
                </mat-card-subtitle>

                <div *ngFor="let errorMessage of getErrorMessages()">
                    <mat-error> - {{ errorMessage }} </mat-error>
                </div>

                <ng-container *ngIf="field('batteryPackage').touched">
                    <mat-error *ngIf="(field('batteryPackage').errors | firstError) === 'required'">
                        - Please select an {{ txt.EligibleBattery }}
                    </mat-error>
                    <mat-error *ngIf="(field('batteryPackage').errors | firstError) === 'idNotFound'">
                        - Please select a valid {{ txt.EligibleBattery }} from the list
                    </mat-error>
                </ng-container>

                <ng-container *ngIf="isBatteryAndSolarSystem() && field('solarPackage').touched">
                    <mat-error *ngIf="(field('solarPackage').errors | firstError) === 'required'">
                        - Please select an Eligible Solar Panel System
                    </mat-error>
                    <mat-error *ngIf="(field('solarPackage').errors | firstError) === 'idNotFound'">
                        - Please select a valid Eligible Solar Panel System from the list
                    </mat-error>
                </ng-container>

                <ng-container *ngIf="field('dcIsolatorPackage').touched">
                    <mat-error *ngIf="(field('dcIsolatorPackage').errors | firstError) === 'required'">
                        - Please select a DC isolator
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !isBatteryAndSolarSystem() &&
                            (field('dcIsolatorPackage').errors | firstError) === 'idNotFound'
                        "
                    >
                        - Please select a valid DC isolator from the list, or enter no DC isolator
                    </mat-error>
                    <mat-error
                        *ngIf="
                            isBatteryAndSolarSystem() &&
                            (field('dcIsolatorPackage').errors | firstError) === 'idNotFound'
                        "
                    >
                        - Please select a valid DC isolator from the list
                    </mat-error>
                </ng-container>

                <mat-error *ngIf="field('inverterPackage').touched && field('inverterPackage').hasError('idNotFound')">
                    - Please select a valid inverter from the list, or enter no inverter
                </mat-error>

                <mat-error
                    *ngIf="
                        field('newSolarPanelInstallationType').touched &&
                        field('newSolarPanelInstallationType').hasError('required')
                    "
                >
                    - Please specify the new solar panel installation type
                </mat-error>

                <mat-error *ngIf="(field('existingSolarPanelCapacity').errors | firstError) === 'pattern'">
                    - Please enter a valid size for the existing solar panel system
                </mat-error>
                <mat-error *ngIf="(field('existingSolarPanelCapacity').errors | firstError) === 'required'">
                    - Please enter the size of the existing solar panel system
                </mat-error>

                <mat-error *ngIf="(field('existingBatteryCapacity').errors | firstError) === 'pattern'">
                    - Please enter a valid size for the existing battery system
                </mat-error>
                <mat-error *ngIf="(field('existingBatteryCapacity').errors | firstError) === 'required'">
                    - Please enter the size of the solar battery system
                </mat-error>

                <mat-error
                    *ngIf="
                        field('newBatteryInstallationType').touched &&
                        field('newBatteryInstallationType').hasError('required')
                    "
                >
                    - Please specify the new battery installation type
                </mat-error>

                <mat-error *ngIf="field('approvedBuilder').touched && field('approvedBuilder').hasError('required')">
                    - Please select a builder
                </mat-error>

                <mat-error *ngIf="field('packagePrice').hasError('required') && field('packagePrice').touched">
                    - Please enter the eligible system price
                </mat-error>

                <mat-error *ngIf="field('packagePrice').hasError('pattern') && field('packagePrice').touched">
                    - Please enter a valid eligible system price
                </mat-error>

                <mat-error *ngIf="field('solarPanelPrice').hasError('required') && field('solarPanelPrice').touched">
                    - Please enter a valid solar panel price or, if not installing panels, change the installation type
                    to battery only
                </mat-error>

                <mat-error *ngIf="(field('newSolarPanelCapacity').errors | firstError) === 'pattern'">
                    - Please enter a valid size for the solar panel system to be installed
                </mat-error>
                <mat-error *ngIf="(field('newSolarPanelCapacity').errors | firstError) === 'required'">
                    - Please the size of the solar panel system to be installed
                </mat-error>

                <mat-error
                    *ngIf="field('installationPrice').hasError('required') && field('installationPrice').touched"
                >
                    - Please enter the installation price
                </mat-error>

                <mat-error *ngIf="field('installationPrice').hasError('pattern') && field('installationPrice').touched">
                    - Please enter a valid installation price
                </mat-error>

                <mat-error
                    *ngIf="
                        field('noInterestBearingLoan').hasError('required') && field('noInterestBearingLoan').touched
                    "
                >
                    - The total price exceeds the Empowering Home Program interest-free loan cap by ${{
                        quoteCalculationModel.balancePayableExcess | number: '1.2-2'
                    }}. Please specify how the extra balance will be paid
                </mat-error>

                <mat-error *ngIf="field('customerDeposit').hasError('required') && field('customerDeposit').touched">
                    - Please enter the customer {{ txt.deposit }}
                </mat-error>

                <mat-error *ngIf="field('customerDeposit').hasError('pattern') && field('customerDeposit').touched">
                    - Please enter a valid customer {{ txt.deposit }}
                </mat-error>

                <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'minimumValue'">
                    - Balance payable is below the minimum loan amount of {{ minimumLoanAmountNsw }}
                </mat-error>

                <mat-error *ngIf="field('estimatedSubsidy').hasError('dependentValueNegative')">
                    - The estimated subsidy exceeds the total price of the combined system.
                </mat-error>

                <mat-error *ngIf="field('providerCustomerId').hasError('required')">
                    - Please enter the {{ providerName }} customer identification
                </mat-error>

                <mat-error *ngFor="let error of externalSubmitErrors"> - {{ error }} </mat-error>
            </mat-card>
            <br />
        </section>

        <section *ngIf="isCrudCreateMode() && isSaScheme() && isSubsidyRateAnnounced()">
            <h3>Current subsidy rate details</h3>
            <mat-card fxLayout="column">
                <p>
                    This information below is accurate as at {{ timestamp | amDateFormat: 't, dd/MM/yyyy' }}. Your
                    client's subsidy rate is only confirmed after they have applied and been conditionally approved for
                    a subsidy. The prevailing subsidy rate could change in the interim.
                </p>
                <ul>
                    <li>
                        Current non-concession recipient subsidy rate:
                        {{ converter.toCurrency(subsidyRate?.nonConcessionPricePerKwH || 0) }} per kWh
                    </li>
                    <li>
                        Current concession recipient subsidy rate:
                        {{ converter.toCurrency(subsidyRate?.concessionPricePerKwH || 0) }} per kWh
                    </li>
                    <li *ngIf="subsidyCountMessage !== null">{{ subsidyCountMessage }}</li>
                </ul>
            </mat-card>
            <br />
        </section>

        <h3>Customer details</h3>
        <mat-card fxLayout="column">
            <p>
                Customer details must be entered accurately. In particular, please ensure that the customer's email
                address and mobile number are accurate, as these cannot be edited at a later date
            </p>
            <div
                *ngIf="isCrudViewMode()"
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="20px"
                fxLayoutGap.lt-md="0px"
                style="margin-bottom: 25px;"
            >
                <div fxFlex="40%;" fxFlex.xs="80%">First name : {{ quoteFormModel.firstName }}</div>
                <div fxFlex="40%;" fxFlex.xs="80%">Last name : {{ quoteFormModel.lastName }}</div>
            </div>
            <div
                *ngIf="!isCrudViewMode()"
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="20px"
                fxLayoutGap.lt-md="0px"
            >
                <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                    <input
                        matInput
                        type="text"
                        placeholder="First name"
                        formControlName="firstName"
                        data-cy="input.firstName"
                    />
                    <mat-error *ngIf="field('firstName').hasError('required')">
                        Please enter a first name
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                    <input
                        matInput
                        type="text"
                        placeholder="Last name"
                        formControlName="lastName"
                        data-cy="input.lastName"
                    />
                    <mat-error *ngIf="field('lastName').hasError('required')">
                        Please enter a last name
                    </mat-error>
                </mat-form-field>
            </div>
            <div
                *ngIf="isCrudViewMode()"
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="20px"
                fxLayoutGap.lt-md="0px"
                style="margin-bottom: 25px;"
            >
                <div fxFlex="40%;" fxFlex.xs="80%">Email : {{ quoteFormModel.email }}</div>
                <div fxFlex="40%;" fxFlex.xs="80%">Mobile : {{ quoteFormModel.mobile }}</div>
            </div>
            <div
                *ngIf="!isCrudViewMode()"
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="20px"
                fxLayoutGap.lt-md="0px"
            >
                <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                    <input matInput type="email" placeholder="Email" formControlName="email" data-cy="input.email" />
                    <mat-error *ngIf="field('email').hasError('required')">
                        Please enter an email address
                    </mat-error>
                    <mat-error *ngIf="!field('email').hasError('required') && field('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                    <input matInput type="tel" placeholder="Mobile" formControlName="mobile" data-cy="input.mobile" />
                    <mat-error *ngIf="field('mobile').hasError('required')">
                        Please enter a mobile number
                    </mat-error>
                    <mat-error *ngIf="field('mobile').hasError('pattern')">
                        Please enter a valid mobile number
                    </mat-error>
                </mat-form-field>
            </div>

            <div
                *ngIf="isCrudViewMode() && isSaScheme() && quoteFormModel.energyConcessionRecipient"
                style="margin-bottom: 25px;"
            >
                <div>The customer receives a Government of South Australia energy concession</div>
            </div>
            <div *ngIf="!isCrudViewMode() && isSaScheme()" class="form-field">
                <mat-checkbox
                    (change)="onEnergyConcessionRecipientChange($event)"
                    formControlName="energyConcessionRecipient"
                    data-cy="checkbox.energyConcessionRecipient"
                >
                    The homeowner is an energy concession recipient
                </mat-checkbox>
                <div *ngIf="field('energyConcessionRecipient').value" class="field-warning">
                    In order to qualify, the customer must live at the installation address for the
                    {{ txt.EligibleEquipment }}
                </div>
            </div>

            <div
                *ngIf="isCrudViewMode() && isSaScheme() && quoteFormModel.statutoryPremiumFeedInMember"
                style="margin-bottom: 25px;"
            >
                <div>The customer is a member of the statutory premium feed-in scheme</div>
            </div>
            <div *ngIf="!isCrudViewMode() && isSaScheme()" class="form-field">
                <mat-checkbox
                    formControlName="statutoryPremiumFeedInMember"
                    data-cy="checkbox.statutoryPremiumFeedInMember"
                >
                    The customer is a member of the statutory premium feed-in scheme
                </mat-checkbox>
                <div *ngIf="field('statutoryPremiumFeedInMember').value" class="field-warning">
                    By selecting this option you confirm that the relevant
                    <button class="popup-anchor" type="button" (click)="openFeedInDialog()" data-cy="button.feedIn">
                        Feed In Scheme
                    </button>
                    has been communicated to the customer and they are aware of the Feed In Tariff eligibility
                    implications of battery addition (or solar PV increase)
                </div>
            </div>
        </mat-card>
        <br />

        <section *ngIf="isCrudViewMode() && isSaScheme()">
            <quote-finance-details
                [applicationGuid]="applicationGuid"
                [financeAmount]="quoteCalculationModel.financeAmount"
                [quoteExternalId]="quoteExternalId"
            ></quote-finance-details>
            <br />
        </section>

        <section *ngIf="isEaas">
            <h3>Energy as a Service</h3>
            <mat-card fxLayout="column">
                <div
                    *ngIf="isCrudViewMode()"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">Is EaaS Quote</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        True
                    </div>
                </div>

                <div
                    *ngIf="isCrudViewMode()"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">{{ providerName }} customer identification</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{ quoteFormModel.providerCustomerId }}
                    </div>
                </div>

                <div
                    *ngIf="!isCrudViewMode()"
                    class="form-field text-field"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">{{ providerName }} customer identification</div>

                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input
                            matInput
                            type="text"
                            placeholder="{{ providerName }} customer identification"
                            formControlName="providerCustomerId"
                            data-cy="input.providerCustomerId"
                        />
                        <mat-error *ngIf="field('providerCustomerId').hasError('required')">
                            Please enter the customer identification
                        </mat-error>
                    </mat-form-field>
                </div>

                <div
                    *ngIf="!isCrudViewMode()"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    class="form-field text-field"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">Billing Owner</div>
                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input
                            matInput
                            placeholder="Billing owner"
                            formControlName="billingOwner"
                            name="billingOwner"
                            data-cy="input.billingOwner"
                            readonly
                        />
                    </mat-form-field>
                </div>
                <div
                    *ngIf="isCrudViewMode() && quoteFormModel.billingOwner"
                    fxLayout="row"
                    fxLayout.lt-md="row"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">Billing owner</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">{{ quoteFormModel.billingOwner }}</div>
                </div>
            </mat-card>
            <br />
        </section>

        <h3>Installation details</h3>
        <mat-card fxLayout="column">
            <!-- View Mode -->
            <ng-container *ngIf="isCrudViewMode()">
                <div
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">Installation address</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{ quoteFormModel.installationAddress | fullAddress }}
                    </div>
                </div>

                <div
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">NMI</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{ quoteFormModel.nmi }}
                    </div>
                </div>

                <div
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">Installation Type</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{ quoteFormModel.installationType }}
                    </div>
                </div>

                <div
                    *ngIf="!isSapnScheme()"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">Proposed installation date</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{ quoteFormModel.intendedInstallationDate | amDateFormat: 'dd/MM/yyyy' }}
                    </div>
                </div>
            </ng-container>

            <!-- Non View Mode -->
            <ng-container *ngIf="!isCrudViewMode()">
                <div
                    fxLayout="row"
                    fxLayout.lt-md="row"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="80%;" fxFlex.xs="80%">
                        <harmony-address
                            fieldName="installationAddress"
                            [allowedStates]="allowedStates"
                            [parentFormGroup]="quoteForm"
                            [submissionErrors]="submissionErrorSubject.asObservable()"
                            [disabled]="isCrudEditMode() && isNewBuildEditable()"
                        ></harmony-address>
                    </div>

                    <div *ngIf="newBuildSelected()" class="form-field" style="margin-bottom: 35px; margin-top: -10px;">
                        <mat-checkbox formControlName="newBuildConfirmation" data-cy="checkbox.newBuildConfirmation">
                            I confirm that this property is a new build
                        </mat-checkbox>
                        <mat-error
                            *ngIf="
                                field('newBuildConfirmation').touched &&
                                field('newBuildConfirmation').hasError('required')
                            "
                        >
                            Please confirm
                        </mat-error>
                    </div>
                </div>

                <div
                    *ngIf="isSapnScheme()"
                    class="form-field text-field"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">
                        National Meter Identifier (NMI)
                        <br />
                        <span class="field-warning small-warning">
                            The NMI is found on an electricity bill issued by a retailer. You will need to request this
                            from the customer
                        </span>
                    </div>

                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input
                            matInput
                            type="text"
                            placeholder="National Meter Identifier (NMI)"
                            formControlName="nmi"
                            data-cy="input.nmi"
                        />
                        <mat-error *ngIf="field('nmi').hasError('required')">
                            Please enter the NMI
                        </mat-error>
                        <mat-error *ngIf="field('nmi').hasError('pattern')">
                            Please enter a valid NMI
                        </mat-error>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="!isSapnScheme()">
                    <div
                        fxLayout="row"
                        fxLayout.lt-md="column"
                        fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px"
                        style="margin-bottom: 25px; margin-top: 25px;"
                    >
                        <div fxFlex="40%;" fxFlex.xs="80%" class="label">Installation type</div>
                        <div fxFlex="40%;" fxFlex.xs="80%">
                            <mat-form-field fxFlex="100%">
                                <mat-select
                                    placeholder="Installation type"
                                    (selectionChange)="handleQuoteAmountChange()"
                                    #installationType
                                    formControlName="installationType"
                                    data-cy="select.installationType"
                                    multiple
                                >
                                    <mat-option *ngFor="let status of installationTypes" [value]="status.value">
                                        {{ status.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="
                                        field('installationType').touched &&
                                        field('installationType').hasError('required')
                                    "
                                >
                                    Please select the installation type
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="panelInstallationTypeRequired()">
                <div
                    *ngIf="!isCrudViewMode()"
                    class="form-field"
                    fxLayout.lt-md="column"
                    fxLayout="row"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    class="text-field"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">New solar panel installation type</div>
                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <mat-select
                            placeholder="New solar panel installation type"
                            #newSolarPanelInstallationType
                            formControlName="newSolarPanelInstallationType"
                            name="newSolarPanelInstallationType"
                            data-cy="select.newSolarPanelInstallationType"
                        >
                            <mat-option *ngFor="let status of panelInstallationTypes" [value]="status.value">
                                {{ status.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                field('newSolarPanelInstallationType').touched &&
                                field('newSolarPanelInstallationType').hasError('required')
                            "
                        >
                            Please specify the panel installation type
                        </mat-error>
                    </mat-form-field>
                </div>

                <div
                    *ngIf="isCrudViewMode()"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px; margin-top: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">New solar panel installation type</div>
                    <br />
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{
                            quoteFormModel.newSolarPanelInstallationType === 'Replacement'
                                ? panelInstallationTypes[0].name
                                : panelInstallationTypes[1].name
                        }}
                    </div>
                </div>
            </ng-container>

            <div
                *ngIf="!isCrudViewMode() && !isSapnScheme()"
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="20px"
                fxLayoutGap.lt-md="0px"
                class="text-field"
            >
                <div fxFlex="40%;" fxFlex.xs="80%" class="label">Proposed installation date</div>

                <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                    <input
                        matInput
                        readonly
                        [matDatepicker]="installationDatePicker"
                        placeholder="Proposed installation date (DD/MM/YYYY)"
                        formControlName="intendedInstallationDate"
                        (focus)="installationDatePicker.open()"
                        (click)="installationDatePicker.open()"
                        [min]="today"
                        data-cy="date.intendedInstallationDate"
                    />
                    <mat-datepicker-toggle matPrefix [for]="installationDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #installationDatePicker [touchUi]="isMobile"></mat-datepicker>
                    <mat-error *ngIf="field('intendedInstallationDate').hasError('required')">
                        Please set a proposed installation date
                    </mat-error>
                    <mat-error *ngIf="field('intendedInstallationDate').hasError('matDatepickerMin')">
                        Please enter a proposed installation date not in the past
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-card>
        <br />

        <h3 *ngIf="isSapnScheme()">Activities</h3>
        <mat-card fxLayout="column" *ngIf="isSapnScheme() && !isCrudViewMode()">
            <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                <mat-select
                    placeholder="MAC Activities Type"
                    name="sapnMacActivities"
                    [(value)]="selectedActivityType"
                    data-cy="select.activity"
                >
                    <mat-option *ngFor="let activity of sapnMacActivityType" [value]="activity">
                        {{ sapnMacActivityTypeLabel[activity] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div
                fxFlex="40%;"
                fxFlex.xs="80%"
                fxLayout="row"
                fxLayoutGap="20px"
                fxLayoutGap.lt-md="0px"
                fxLayout.lt-md="column"
            >
                <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                    <mat-select
                        placeholder="Mac Rebate Type"
                        name="sapnMacRebateType"
                        [(value)]="selectedActivityRebateType"
                        data-cy="select.rebateType"
                    >
                        <mat-option *ngFor="let rebateType of sapnMacActivitiesRebate" [value]="rebateType">
                            {{ rebateType }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="20%;" fxFlex.xs="80%" *ngIf="isMacActivityVppRequired()">
                    <mat-select placeholder="Vpp" name="sapnMacVppType" [(value)]="selectedActivityVpp" data-cy="select.activityVpp">
                        <mat-option *ngFor="let vpp of sapnMacActivitiesVpp" [value]="vpp">
                            {{ vpp }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="isMacActivitySystemAndInstallationCostRequired()">
                    <input
                        matInput
                        formControlName="systemAndInstallationCost"
                        name="systemAndInstallationCost"
                        placeholder="System cost"
                        type="number"
                        data-cy="input.systemAndInstallationCost"
                    />
                    <mat-error *ngIf="field('systemAndInstallationCost').hasError('systemAndInstallationCostRequired') || field('systemAndInstallationCost').hasError('required')">
                        System cost is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="isMacActivityAdditionalCostRequired()">
                    <input
                        matInput
                        formControlName="additionalCost"
                        name="additionalCost"
                        placeholder="Additional cost"
                        type="number"
                        data-cy="input.additionalCost"
                    />
                    <mat-error *ngIf="field('additionalCost').hasError('additionalCostRequired') || field('additionalCost').hasError('required')">
                        Additional cost is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        formControlName="activityRebateAmount"
                        name="activityRebateAmount"
                        placeholder="Rebate Amount"
                        type="number"
                        data-cy="input.rebateAmount"
                    />
                    <mat-error *ngIf="field('activityRebateAmount').hasError('amountExceed')">
                        Total of each associated rebate must be under 2500
                    </mat-error>
                    <mat-error *ngIf="field('activityRebateAmount').hasError('missingFields')">
                        Please fill out all activity fields
                    </mat-error>
                    <mat-error *ngIf="field('activityRebateAmount').hasError('vppRequired')">
                        Vpp is required.
                    </mat-error>
                </mat-form-field>

                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    (click)="addActivity()"
                    data-cy="button.addActivity"
                >
                    Add Activity
                </button>
            </div>
            <br />
            <table class="table">
                <thead>
                    <th>Activity Type</th>
                    <th>Associated Rebate</th>
                    <th>Vpp</th>
                    <th>System cost</th>
                    <th>Additional cost</th>
                    <th>Rebate Amount</th>
                    <th>Sub-total</th>
                </thead>
                <tbody formArrayName="activities">
                    <tr
                        *ngFor="let itemrow of quoteForm.controls.activities.controls; let i = index"
                        [formGroupName]="i"
                    >
                        <td style="text-align: center;">
                            <input
                                matInput
                                readonly
                                [value]="sapnMacActivityTypeLabel[field('activities').controls[i].get('activityType').value]"
                            />
                        </td>
                        <td style="text-align: center;">
                            <input matInput readonly formControlName="associatedRebate" name="associatedRebate" />
                        </td>
                        <td style="text-align: center;">
                            <input matInput readonly formControlName="vpp" name="vpp" />
                        </td>
                        <td style="text-align: center;">
                            <financial-table-value
                                [value]="field('activities').controls[i].get('systemAndInstallationCost').value"
                            ></financial-table-value>
                        </td>
                        <td style="text-align: center;">
                            <financial-table-value
                                [value]="field('activities').controls[i].get('additionalCost').value"
                            ></financial-table-value>
                        </td>
                        <td style="text-align: center;">
                            <financial-table-value
                                [value]="field('activities').controls[i].get('rebateAmount').value"
                            ></financial-table-value>
                        </td>
                        <td style="text-align: center;">
                            <financial-table-value
                                [value]="field('activities').controls[i].get('subtotal').value"
                            ></financial-table-value>
                        </td>
                        <td style="text-align: center;">
                            <button type="button" mat-raised-button color="warn" (click)="deleteActivity(i)">
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-card>

        <mat-card fxLayout="column" *ngIf="isSapnScheme() && isCrudViewMode()">
            <table *ngIf="quoteFormModel.activities.length > 0" class="mat-elevation-z8">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Associated Rebate</th>
                        <th>Sub-total</th>
                        <th>VPP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let activity of quoteFormModel.activities">
                        <td style="text-align: center;">{{ activity.activityType }}</td>
                        <td style="text-align: center;">{{ activity.associatedRebate }}</td>
                        <td style="text-align: center;">{{ activity.subtotal }}</td>
                        <td style="text-align: center;">{{ activity.vpp }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card>

        <br />

        <h3>{{ txt.EligibleEquipment }} details</h3>
        <mat-card fxLayout="column">
            <ng-container *ngIf="!isSapnScheme()">
                <package-select
                    [isViewMode]="isCrudViewMode()"
                    (onPackageSelected)="packageTypeChange($event)"
                    [control]="field('batteryPackage')"
                    category="battery"
                    [scheme]="quoteFormModel?.scope || quoteScope"
                    [wideField]="isSaScheme()"
                    [scoped]="isSaScheme()"
                    fieldDisplayName="{{ txt.EligibleBattery }}"
                >
                </package-select>
            </ng-container>

            <!-- Battery, Solar, Inverter -->
            <ng-container *ngIf="hasBattery() || hasSolarSystem()">
                <ng-container *ngIf="hasBattery()">
                    <div
                        fxLayout.gt-xs="row"
                        fxLayoutGap.gt-xs="16px"
                        fxLayout.xs="column"
                        fxLayoutGap.xs="8px"
                        fxLayoutAlign.gt-xs=" center"
                    >
                        <span fxFlex.gt-xs="30" fxFlex.xs class="info-title">Installed battery system</span>
                        <span fxFlex="grow" fxLayout="column">
                            <bp-cec-package-select
                                formControlName="batteryPackage"
                                category="battery"
                                (selectedPackageChange)="_batteryCecSelected($event)"
                                placeholder="Enter the installed battery"
                                data-cy="input.batteryPackage"
                            >
                                <mat-error
                                    *ngIf="
                                        quoteForm.get('batteryPackage').touched &&
                                        quoteForm.get('batteryPackage').invalid
                                    "
                                >
                                    <ng-container *ngIf="quoteForm.get('batteryPackage').hasError('required')"
                                        >Please enter the battery.</ng-container
                                    >
                                    <ng-container
                                        *ngIf="
                                            quoteForm.get('batteryPackage').hasError('invalid') ||
                                            quoteForm.get('batteryPackage').hasError('notfound')
                                        "
                                    >
                                        Invalid battery.</ng-container
                                    >
                                    <ng-container *ngIf="quoteForm.get('batteryPackage').hasError('ineligibleValue')">
                                        You cannot proceed with this battery on this program ({{
                                            quoteForm.get('batteryPackage').getError('ineligibleValue')[
                                                'renewableOfferProgram'
                                            ]
                                        }}) - please select a valid battery to continue.
                                    </ng-container>
                                </mat-error>
                            </bp-cec-package-select>
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngIf="hasSolarSystem()">
                    <div
                        fxLayout.gt-xs="row"
                        fxLayoutGap.gt-xs="16px"
                        fxLayout.xs="column"
                        fxLayoutGap.xs="8px"
                        fxLayoutAlign.gt-xs=" center"
                    >
                        <span fxFlex.gt-xs="30" fxFlex.xs class="info-title">Installed solar panels system</span>
                        <span fxFlex="grow" fxLayout="column">
                            <bp-cec-package-select
                                formControlName="solarPackage"
                                category="solar"
                                (selectedPackageChange)="_solarPanelSelected($event)"
                                placeholder="Enter the installed solar panels"
                                data-cy="input.solarPackage"
                            >
                                <mat-error
                                    *ngIf="
                                        quoteForm.get('solarPackage').touched && quoteForm.get('solarPackage').invalid
                                    "
                                >
                                    <ng-container *ngIf="quoteForm.get('solarPackage').hasError('required')"
                                        >Please enter the solar panel.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            quoteForm.get('solarPackage').hasError('invalid') ||
                                            quoteForm.get('solarPackage').hasError('notfound')
                                        "
                                    >
                                        Invalid solar panels.</ng-container
                                    >
                                </mat-error>
                            </bp-cec-package-select>
                        </span>
                    </div>
                </ng-container>

                <div
                    fxLayout.gt-xs="row"
                    fxLayoutGap.gt-xs="16px"
                    fxLayout.xs="column"
                    fxLayoutGap.xs="8px"
                    fxLayoutAlign.gt-xs=" center"
                >
                    <span fxFlex.gt-xs="30" fxFlex.xs class="info-title">Installed inverter</span>
                    <span fxFlex="grow" fxLayout="column">
                        <bp-cec-package-select
                            formControlName="inverterPackage"
                            category="inverter"
                            (selectedPackageChange)="_inverterPanelSelected($event)"
                            placeholder="Enter the installed inverter"
                            data-cy="input.inverterPackage"
                        >
                            <mat-error
                                *ngIf="
                                    quoteForm.get('inverterPackage').touched && quoteForm.get('inverterPackage').invalid
                                "
                            >
                                <ng-container *ngIf="quoteForm.get('inverterPackage').hasError('required')"
                                    >Please enter the inverter.</ng-container
                                >
                                <ng-container
                                    *ngIf="
                                        quoteForm.get('inverterPackage').hasError('invalid') ||
                                        quoteForm.get('inverterPackage').hasError('notfound')
                                    "
                                >
                                    Invalid inverter.</ng-container
                                >
                            </mat-error>
                        </bp-cec-package-select>
                    </span>
                </div>
            </ng-container>

            <!-- System model identifiers -->
            <ng-container>
                <!-- HeatPumpWaterHeater -->
                <div
                    fxLayout.gt-xs="row"
                    fxLayoutGap.gt-xs="16px"
                    fxLayout.xs="column"
                    fxLayoutGap.xs="8px"
                    fxLayoutAlign.gt-xs="center"
                    style="margin-top: 16px; margin-bottom: 16px;"
                    *ngIf="hasSystemType('HeatPumpWaterHeater')"
                >
                    <span fxFlex.gt-xs="30" fxFlex.xs class="info-title">Installed Heat pump and hot heater</span>
                    <span fxFlex="grow" fxLayout="column">
                        <mat-select 
                            [disabled]="isCrudViewMode()"
                            [placeholder]="
                                'Enter the system model identifier of ' + getSystemModelIdentifierLabels('HeatPumpWaterHeater')
                            " 
                            [formControlName]="'systemModelIdentifier_HeatPumpWaterHeater'"
                            [attr.data-cy]="'select.systemModelIdentifierHeatPumpWaterHeater'"
                        >
                            <mat-option value="iStore 180">iStore 180</mat-option>
                            <mat-option value="iStore 270">iStore 270</mat-option>
                        </mat-select>
                    </span>
                </div>

                <!-- SplitSystemAirConditioner -->
                <div
                    fxLayout.gt-xs="row"
                    fxLayoutGap.gt-xs="16px"
                    fxLayout.xs="column"
                    fxLayoutGap.xs="8px"
                    fxLayoutAlign.gt-xs="center"
                    style="margin-top: 16px; margin-bottom: 16px;"
                    *ngIf="hasSystemType('SplitSystemAirConditioner')"
                >
                    <span fxFlex.gt-xs="30" fxFlex.xs class="info-title">Installed split system air conditioner</span>
                    <span fxFlex="grow" fxLayout="column">
                        <mat-select 
                            [disabled]="isCrudViewMode()"
                            [placeholder]="
                                'Enter the system model identifier of ' + getSystemModelIdentifierLabels('SplitSystemAirConditioner')
                            " 
                            [formControlName]="'systemModelIdentifier_SplitSystemAirConditioner'"
                            [attr.data-cy]="'select.systemModelIdentifierSplitSystemAirConditioner'"
                        >
                            <mat-option value="ActronAir Small 2.6Kw">ActronAir Small 2.6Kw</mat-option>
                            <mat-option value="ActronAir Medium 3.5Kw">ActronAir Medium 3.5Kw</mat-option>
                            <mat-option value="ActronAir Large 5Kw">ActronAir Large 5Kw</mat-option>
                            <mat-option value="ActronAir Extra-large 7.2Kw">ActronAir Extra-large 7.2Kw</mat-option>
                        </mat-select>
                    </span>
                </div>

                <!-- EVCharger -->
                <div
                    fxLayout.gt-xs="row"
                    fxLayoutGap.gt-xs="16px"
                    fxLayout.xs="column"
                    fxLayoutGap.xs="8px"
                    fxLayoutAlign.gt-xs="center"
                    style="margin-top: 16px; margin-bottom: 16px;"
                    *ngIf="hasSystemType('EVCharger')"
                >
                    <span fxFlex.gt-xs="30" fxFlex.xs class="info-title">Installed EV Charger</span>
                    <span fxFlex="grow" fxLayout="column">
                        <mat-select 
                            [disabled]="isCrudViewMode()" 
                            [placeholder]="
                                'Enter the system model identifier of ' + getSystemModelIdentifierLabels('EVCharger')
                            " 
                            [formControlName]="'systemModelIdentifier_EVCharger'"
                            [attr.data-cy]="'select.systemModelIdentifierEVCharger'"
                        >
                            <mat-option value="Schneider EV Link">Schneider EV Link</mat-option>
                        </mat-select>
                    </span>
                </div>

                <div
                    *ngFor="let systemType of getSystemTypesExceptPrefilled()"
                    fxLayout.gt-xs="row"
                    fxLayoutGap.gt-xs="16px"
                    fxLayout.xs="column"
                    fxLayoutGap.xs="8px"
                    fxLayoutAlign.gt-xs="center"
                    style="margin-top: 16px; margin-bottom: 16px;"
                >
                    <span fxFlex.gt-xs="30" fxFlex.xs class="info-title">{{
                        getSystemModelIdentifierLabels(systemType)
                    }}</span>
                    <span fxFlex.gt-xs="70" fxFlex.xs class="info-title">
                        <input
                            matInput
                            [disabled]="isCrudViewMode()"
                            type="text"
                            [placeholder]="
                                'Enter the system model identifier of ' + getSystemModelIdentifierLabels(systemType)
                            "
                            [formControlName]="'systemModelIdentifier_' + systemType"
                            [attr.data-cy]="'input.systemModelIdentifier' + systemType"
                            style="border-bottom: 1px solid #000;"
                        />
                    </span>
                </div>
            </ng-container>
        </mat-card>
        <br />

        <mat-card fxLayout="column" *ngIf="newBuildSelected()">
            <h3>Builder details</h3>
            <ng-container *ngIf="isCrudViewMode()">
                <div
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;"
                >
                    <div fxFlex="40%;" fxFlex.xs="80%">Builder</div>
                    <br />
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{ selectedBuilder ? selectedBuilder.name : quoteFormModel.approvedBuilder }}
                    </div>
                </div>
            </ng-container>
        </mat-card>
        <br *ngIf="newBuildSelected()" />
        <h3>Price details</h3>
        <mat-card fxLayout="column">
            <ul>
                <li>
                    <strong>Prices must be entered {{ txt.gstStatus }} of GST</strong>
                </li>
                <li>
                    Price details must be entered accurately, and must reflect the price you have provided to the
                    customer in any quote communications
                </li>
                <li *ngIf="isSaScheme()">
                    Subsidy amounts may not be paid to you if these price details do not match the prices specified on
                    the invoice provided to the customer following installation
                </li>
            </ul>
            <ng-container *ngIf="isSapnScheme()">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%">Total system and installation costs</div>

                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="totalSystemAndInstallationCosts"></financial-table-value>
                    </div>
                </div>
                <br />
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%">Total subsidies to the customer</div>

                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="totalActivityRebate"></financial-table-value>
                    </div>
                </div>
                <br />
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%">Total addtional cost</div>

                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="totalAdditionalCost"></financial-table-value>
                    </div>
                </div>
                <br />
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field" *ngIf="!isCrudViewMode()">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">Customer Deposit</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <mat-form-field>
                            <input matInput type="text" placeholder="Customer {{ txt.deposit }} ($)" formControlName="customerDeposit"
                                #customerDeposit name="customerDeposit"
                                (keyup)="handleQuoteAmountChange(customerDeposit.value, customerDeposit.name)"
                                (change)="handleQuoteAmountChange(customerDeposit.value, customerDeposit.name)"
                                data-cy="input.customerDeposit" />
                            <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'required'">
                                Please enter the amount
                            </mat-error>
                            <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'pattern'">
                                Please enter a valid amount
                            </mat-error>
                            <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'dependentValueNegative'">
                                {{ txt.Deposit }} amount exceeds balance payable
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field" *ngIf="isCrudViewMode()">
                    <div fxFlex="40%;" fxFlex.xs="80%">Customer Deposit</div>
                    <financial-table-value [value]="field('customerDeposit').value"></financial-table-value>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%">Balance payable by customer</div>

                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="getTotalAmountOutstanding()"></financial-table-value>
                    </div>
                </div>
                <br />
            </ng-container>
            

            <ng-container *ngIf="!isSapnScheme()">
                <br />
                <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;">
                    <div fxFlex="40%;" fxFlex.xs="80%">{{ txt.EligibleBatteryPrice }}</div>

                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="field('packagePrice').value"></financial-table-value>
                    </div>
                </div>
                <div *ngIf="!isCrudViewMode() && hasBattery()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px" class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">{{ txt.EligibleBatteryPrice }}</div>

                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input matInput type="text" formControlName="packagePrice" placeholder="{{ txt.EligibleBatteryPrice }} ($)"
                            #packagePrice name="packagePrice"
                            (keyup)="handleQuoteAmountChange(packagePrice.value, packagePrice.name)"
                            (change)="handleQuoteAmountChange(packagePrice.value, packagePrice.name)"
                            data-cy="input.packagePrice" />
                        <mat-error *ngIf="field('packagePrice').hasError('required')">
                            Please enter the amount
                        </mat-error>
                        <mat-error *ngIf="field('packagePrice').hasError('pattern')">
                            Please enter a valid amount
                        </mat-error>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="shouldDisplayPanelPrice()">
                    <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                        style="margin-bottom: 25px;">
                        <div fxFlex="40%;" fxFlex.xs="80%">{{ txt.EligiblePanelPrice }}</div>

                        <div fxFlex="40%;" fxFlex.xs="80%">
                            <financial-table-value [value]="field('solarPanelPrice').value"></financial-table-value>
                        </div>
                    </div>
                    <div *ngIf="!isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                        class="text-field">
                        <div fxFlex="40%;" fxFlex.xs="80%" class="label">{{ txt.EligiblePanelPrice }}</div>

                        <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                            <input matInput type="text" #solarPanelPrice formControlName="solarPanelPrice"
                                placeholder="{{ txt.EligiblePanelPrice }} ($)" name="solarPanelPrice"
                                (keyup)="handleQuoteAmountChange(solarPanelPrice.value, solarPanelPrice.name)"
                                (change)="handleQuoteAmountChange(solarPanelPrice.value, solarPanelPrice.name)"
                                data-cy="input.solarPanelPrice" />
                            <mat-error *ngIf="(field('solarPanelPrice').errors | firstError) === 'pattern'">
                                Please enter a valid amount
                            </mat-error>
                            <mat-error *ngIf="(field('solarPanelPrice').errors | firstError) === 'required'">
                                Enter amount or change installation to battery only
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isCrudViewMode()">
                    <div *ngFor="let sysType of getSystemTypesExceptBatteryAndSolar()">
                        <div
                            *ngIf="hasSystem(sysType)"
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap="20px"
                            fxLayoutGap.lt-md="0px"
                            class="text-field"
                        >
                            <div fxFlex="40%;" fxFlex.xs="80%" class="label">
                                Eligible {{ sysType }} price (GST inclusive)
                            </div>

                            <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                                <input
                                    matInput
                                    type="text"
                                    [formControlName]="'price_' + sysType"
                                    [placeholder]="'Eligible ' + sysType + ' price (GST inclusive)'"
                                    [name]="'price_' + sysType"
                                    [data-cy]="'input.price' + sysType"
                                    (keyup)="handleQuoteAmountChange($event.target.value, 'price_' + sysType)"
                                    (change)="handleQuoteAmountChange($event.target.value, 'price_' + sysType)"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isCrudViewMode()">
                    <div *ngFor="let sysType of quoteFormModel.installationType">
                        <div
                            *ngIf="notSolarOrBattery(sysType)"
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap="20px"
                            fxLayoutGap.lt-md="0px"
                            class="text-field"
                            style="margin-bottom: 25px;"
                        >
                            <div fxFlex="40%;" fxFlex.xs="80%" class="label">
                                Eligible {{ sysType }} price (GST inclusive)
                            </div>
                            <div fxFlex="40%;" fxFlex.xs="80%">
                                <financial-table-value [value]="getSystemPriceForViewMode(sysType)"></financial-table-value>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;">
                    <div fxFlex="40%;" fxFlex.xs="80%">Installation price{{ txt.gstInclusive }}</div>

                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="field('installationPrice').value"></financial-table-value>
                    </div>
                </div>
                <div *ngIf="!isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">Installation price{{ txt.gstInclusive }}</div>

                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input matInput type="text" #installationPrice formControlName="installationPrice"
                            placeholder="Installation price{{ txt.gstInclusive }} ($)" name="installationPrice"
                            (keyup)="handleQuoteAmountChange(installationPrice.value, installationPrice.name)"
                            (change)="handleQuoteAmountChange(installationPrice.value, installationPrice.name)"
                            data-cy="input.installationPrice" />
                        <mat-error *ngIf="field('installationPrice').hasError('required')">
                            Please enter the amount
                        </mat-error>
                        <mat-error *ngIf="field('installationPrice').hasError('pattern')">
                            Please enter a valid amount
                        </mat-error>
                    </mat-form-field>
                </div>

                <hr *ngIf="isCrudViewMode()" class="subtotal-divider" />

                <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;">
                    <div fxFlex="40%;" fxFlex.xs="80%">Total price (GST exclusive)</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value
                            [value]="nonNegative(quoteCalculationModel.totalGstExclusive)"></financial-table-value>
                    </div>
                </div>
                <div *ngIf="!isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">Total price (GST exclusive)</div>

                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input matInput disabled type="text" placeholder="Total price, GST exclusive ($)"
                            value="{{ quoteCalculationModel.totalGstExclusive | number: '1.2-2' }}" />
                    </mat-form-field>
                </div>

                <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;">
                    <div fxFlex="40%;" fxFlex.xs="80%">Total price (GST inclusive)</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value
                            [value]="nonNegative(quoteCalculationModel.totalGstInclusive)"></financial-table-value>
                    </div>
                </div>
                <div *ngIf="!isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">Total price (GST inclusive)</div>

                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input matInput disabled type="text" placeholder="Total price, GST inclusive ($)"
                            value="{{ quoteCalculationModel.totalGstInclusive | number: '1.2-2' }}" />
                    </mat-form-field>
                </div>

                <hr *ngIf="isCrudViewMode() || isNswScheme()" class="subtotal-divider" />

                <ng-container *ngIf="isSaScheme()">
                    <div
                        *ngIf="isCrudViewMode()"
                        class="deduction"
                        fxLayout="row"
                        fxLayout.lt-md="column"
                        fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px"
                        style="margin-bottom: 25px;"
                    >
                        <div fxFlex="40%;" fxFlex.xs="80%">Estimated subsidy</div>
                        <div fxFlex="40%;" fxFlex.xs="80%">
                            <financial-table-value [value]="quoteCalculationModel.estimatedSubsidy"></financial-table-value>
                        </div>
                    </div>
                    <div
                        *ngIf="!isCrudViewMode()"
                        fxLayout="row"
                        fxLayout.lt-md="column"
                        fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px"
                        class="text-field"
                    >
                        <div fxFlex="40%;" fxFlex.xs="80%" class="label">Estimated subsidy</div>
                        <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                            <input
                                matInput
                                type="text"
                                placeholder="Estimated subsidy ($)"
                                [value]="quoteCalculationModel.estimatedSubsidy"
                                #estimatedSubsidy
                                name="estimatedSubsidy"
                                disabled
                            />
                            <mat-hint
                                class="error-text"
                                *ngIf="field('estimatedSubsidy').hasError('dependentValueNegative')"
                            >
                                The estimated subsidy exceeds the total price of the combined system.
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div *ngIf="isCrudViewMode()" class="deduction" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px" style="margin-bottom: 25px;">
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        Customer {{ txt.deposit }}
                        <ng-container *ngIf="isNswScheme()">
                            <br />
                            <span class="field-warning small-warning">
                                It is an Empowering Home Program requirement that, if requested by the customer, you present
                                a payment option without a deposit
                            </span>
                        </ng-container>
                    </div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="quoteCalculationModel.totalCustomerDeposit"></financial-table-value>
                    </div>
                </div>

                <div *ngIf="!isCrudViewMode()" fxLayout="row"
                    [ngClass]="{ 'long-error': (field('customerDeposit').errors | firstError) === 'dependentHasInvalid' }"
                    fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">
                        Customer {{ txt.deposit }}
                        <ng-container *ngIf="isNswScheme()">
                            <br />
                            <span class="field-warning small-warning">
                                It is an Empowering Home Program requirement that, if requested by the customer, you present
                                a payment option without a deposit
                            </span>
                        </ng-container>
                    </div>

                    <div fxLayout="column" fxFlex="40%;" fxFlex.xs="80%">
                        <mat-form-field>
                            <input matInput type="text" placeholder="Customer {{ txt.deposit }} ($)"
                                formControlName="customerDeposit" #customerDeposit name="customerDeposit"
                                (keyup)="handleQuoteAmountChange(customerDeposit.value, customerDeposit.name)"
                                (change)="handleQuoteAmountChange(customerDeposit.value, customerDeposit.name)"
                                data-cy="input.customerDeposit" />
                            <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'required'">
                                Please enter the amount
                            </mat-error>
                            <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'pattern'">
                                Please enter a valid amount
                            </mat-error>
                            <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'dependentValueNegative'">
                                {{ txt.Deposit }} amount exceeds balance payable
                            </mat-error>
                            <mat-error *ngIf="(field('customerDeposit').errors | firstError) === 'minimumValue'">
                                Balance payable is below the minimum loan amount of ${{
                                minimumLoanAmountNsw | number: '1.2-2'
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <ng-container *ngIf="quoteCalculationModel.balancePayableExcess > 0">
                    <ng-container *ngIf="quoteCalculationModel.balancePayableExcess >= quoteCalculationModel.minimumLoanAmount">
                        <div *ngIf="!isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                            fxLayoutGap.lt-md="0px" style="margin-bottom: 25px;">
                            <div fxFlex="40%;" fxFlex.xs="80%" style="padding-right: 20px">
                                The total amount payable by the customer (after customer payment) exceeds the Empowering
                                Home Program interest-free loan cap by ${{
                                quoteCalculationModel.balancePayableExcess | number: '1.2-2'
                                }}. How will this extra balance be paid?
                            </div>
                            <div fxLayout="column" fxFlex="40%;" fxFlex.xs="80%">
                                <mat-radio-group *ngIf="
                                                quoteCalculationModel.balancePayableExcess >=
                                                quoteCalculationModel.minimumLoanAmount
                                            " aria-label="Select a repayment option" formControlName="noInterestBearingLoan"
                                    #noInterestBearingLoan name="noInterestBearingLoan" (change)="
                                                handleQuoteAmountChange(noInterestBearingLoan.value, noInterestBearingLoan.name)
                                            ">
                                    <div class="radio">
                                        <mat-radio-button [value]="true" data-cy="checkbox.paidByCustomer">It will be paid by the
                                            customer</mat-radio-button>
                                    </div>
                                    <div class="radio">
                                        <mat-radio-button [value]="false" data-cy="checkbox.rateSetterLoan">It will be financed as a
                                            {{ env.constants.branding.title }} interest-bearing
                                            loan</mat-radio-button>
                                    </div>
                                    <mat-error *ngIf="
                                                    field('noInterestBearingLoan').hasError('required') &&
                                                    field('noInterestBearingLoan').touched
                                                ">
                                        Please specify
                                    </mat-error>
                                </mat-radio-group>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="hasExtraDeposit()">
                        <div *ngIf="!isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                            fxLayoutGap.lt-md="0px">
                            <div fxFlex="40%;" fxFlex.xs="0%"></div>
                            <div fxFlex="40%;" fxFlex.xs="80%" fxLayout="column">
                                <div>
                                    <mat-form-field floatLabel="always">
                                        <input matInput disabled type="text" placeholder="Extra payment required ($)" value="{{
                                                        nonNegative(quoteCalculationModel.additionalRequiredDeposit)
                                                            | number: '1.2-2'
                                                    }}" />
                                    </mat-form-field>
                                    <span *ngIf="
                                                    quoteCalculationModel.balancePayableExcess <
                                                    quoteCalculationModel.minimumLoanAmount
                                                " class="badge-help" matTooltip="Because the balance exceeding the Empowering Home Program interest-free loan cap is under {{
                                                    env.constants.branding.title
                                                }}'s minimum loan of $1,000, the balance must be paid by the customer">?</span>
                                </div>
                                <mat-form-field floatLabel="always">
                                    <input matInput disabled type="text" placeholder="Total customer payment ($)"
                                        value="{{ quoteCalculationModel.totalCustomerDeposit | number: '1.2-2' }}" />
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <hr *ngIf="isCrudViewMode()" class="subtotal-divider" />

                <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;" class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">Balance payable by customer (GST inclusive)</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        <financial-table-value [value]="quoteCalculationModel.balancePayable"></financial-table-value>
                    </div>
                </div>
                <div *ngIf="!isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    class="text-field">
                    <div fxFlex="40%;" fxFlex.xs="80%" class="label">Balance payable by customer (GST inclusive)</div>

                    <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                        <input matInput disabled type="text" placeholder="Balance payable, GST inclusive ($)"
                            value="{{ nonNegative(quoteCalculationModel.balancePayable) | number: '1.2-2' }}" />
                    </mat-form-field>
                </div>

                <ng-container *ngIf="isNswScheme()">
                    <div *ngIf="isCrudViewMode()" class="subvalue" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px" style="margin-bottom: 25px;">
                        <div fxFlex="40%;" fxFlex.xs="80%">Payable via Empowering Home Program interest-free loan</div>
                        <div fxFlex="40%;" fxFlex.xs="80%">
                            <financial-table-value
                                [value]="quoteCalculationModel.balancePayableInterestFree"></financial-table-value>
                        </div>
                    </div>
                    <div *ngIf="!isCrudViewMode()" class="subvalue" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px" class="text-field">
                        <div fxFlex="40%;" fxFlex.xs="80%" class="label">
                            Payable via Empowering Home Program interest-free loan
                        </div>

                        <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                            <input matInput disabled type="text"
                                placeholder="Payable via Empowering Home Program interest-free loan" value="{{
                                            nonNegative(quoteCalculationModel.balancePayableInterestFree) | number: '1.2-2'
                                        }}" />
                        </mat-form-field>
                    </div>

                    <ng-container *ngIf="!isEaas">
                        <div *ngIf="isCrudViewMode()" class="subvalue" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                            fxLayoutGap.lt-md="0px" style="margin-bottom: 25px;">
                            <div fxFlex="40%;" fxFlex.xs="80%">
                                Payable via Empowering Home Program interest bearing loan
                            </div>
                            <div fxFlex="40%;" fxFlex.xs="80%">
                                <financial-table-value
                                    [value]="quoteCalculationModel.balancePayableInterestBearing"></financial-table-value>
                            </div>
                        </div>
                        <div *ngIf="!isCrudViewMode()" class="subvalue" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                            fxLayoutGap.lt-md="0px" class="text-field">
                            <div fxFlex="40%;" fxFlex.xs="80%" class="label">
                                Payable via Empowering Home Program interest bearing loan
                            </div>

                            <mat-form-field fxFlex="40%;" fxFlex.xs="80%">
                                <input matInput disabled type="text"
                                    placeholder="Payable via Empowering Home Program interest bearing loan" value="{{
                                                nonNegative(quoteCalculationModel.balancePayableInterestBearing) | number: '1.2-2'
                                            }}" />
                            </mat-form-field>
                        </div>
                    </ng-container>
                </ng-container>

                <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                    style="margin-bottom: 25px;">
                    <div fxFlex="40%;" fxFlex.xs="80%;">Quote Record validity (DD/MM/YYYY)</div>
                    <div fxFlex="40%;" fxFlex.xs="80%;">
                        <div fxLayout="row">
                            <div fxFlex="40%;">{{ quoteFormModel.quoteValidity | amDateFormat: 'dd/MM/yyyy' }}</div>
                            <div *ngIf="isExpiredQuote() && isFeatureInstallerCanRenewQuoteRecordEnabled()" fxFlex="40%;">
                                <button mat-raised-button (click)="openQuoteRenewalConfirmDialog(); $event.preventDefault()"
                                    data-cy="button.renew">
                                    Renew
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isCrudViewMode()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                    <div fxFlex="40%;" fxFlex.xs="80%">Fair pricing confirmation</div>
                    <div fxFlex="40%;" fxFlex.xs="80%">
                        {{
                        quoteFormModel.fairPricingConfirmation ? 'Fair pricing confirmed' : 'Fair pricing not confirmed'
                        }}
                    </div>
                </div>
            </ng-container>

            <section *ngIf="!isCrudViewMode()">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                    <div fxFlex="40%;" fxFlex.xs="80%">Fair pricing confirmation</div>
                    <div fxLayout="column" fxFlex="40%;" fxFlex.xs="80%">
                        <div>
                            <mat-checkbox
                                formControlName="fairPricingConfirmation"
                                data-cy="checkbox.fairPricingConfirmation"
                            >
                                I confirm that:
                            </mat-checkbox>
                        </div>
                        <ul>
                            <li>
                                The price specified in this quote record is the same as the price stated in the quote
                                provided to the customer
                            </li>
                            <li>
                                The price is a cash price and not above the fair value of the proposed system
                            </li>
                            <li>
                                The price has not been inflated to cover the cost of receiving any subsidy or any costs
                                associated with providing finance to this customer (including 'interest-free' merchant
                                fees)
                            </li>
                            <li *ngIf="isNswScheme() && derivedFinanceChangedFromLastQuote()" class="important">
                                I have reviewed the updated payment details and confirm that they are correct.
                            </li>
                        </ul>
                        <div>
                            <mat-error
                                *ngIf="
                                    field('fairPricingConfirmation').touched &&
                                    field('fairPricingConfirmation').hasError('required')
                                "
                            >
                                Please confirm
                            </mat-error>
                        </div>
                    </div>
                </div>
            </section>
        </mat-card>
        <br />

        <quote-documents
            *ngIf="!isCrudCreateMode()"
            [quoteExternalId]="quoteExternalId"
            [quoteStatus]="quoteStatus"
            (onDocumentAvailabilityUpdated)="updateDocumentAvailability($event)"
            [quoteFormModel]="quoteFormModel"
        >
        </quote-documents>

        <section
            *ngIf="
                !isCrudViewMode() ||
                isConfirmable() ||
                quoteStatusService.isEditable(quoteStatus) ||
                quoteStatusService.isCancelable(quoteStatus)
            "
        >
            <ng-container *ngTemplateOutlet="quoteToolbar"></ng-container>
        </section>
    </form>
    <bp-loading-overlay [isLoading]="asyncConfig.isLoading"></bp-loading-overlay>
</section>

<ng-template #quoteToolbar>
    <mat-card fxLayout="column">
        <mat-card-content>
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
                <div fxFlex="40%;" fxFlex.xs="80%" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                    <button
                        *ngIf="isCrudCreateMode() || isCrudEditMode()"
                        type="submit"
                        mat-raised-button
                        color="primary"
                        data-cy="button.submit"
                    >
                        {{ isCrudEditMode() ? 'Update' : 'Submit' }}
                    </button>

                    <button
                        *ngIf="isCrudViewMode()"
                        type="button"
                        mat-raised-button
                        color="primary"
                        (click)="changeToEditMode()"
                        [disabled]="!quoteStatusService.isEditable(quoteStatus) || !actionPermissions.canPatchQuote"
                        data-cy="button.editQuoteRecord"
                    >
                        Edit Quote Record
                    </button>

                    <button
                        *ngIf="isCrudEditMode()"
                        type="button"
                        mat-raised-button
                        color="warn"
                        (click)="cancelEditMode()"
                    >
                        Cancel
                    </button>

                    <button
                        *ngIf="isCrudViewMode()"
                        [disabled]="
                            !quoteStatusService.isCancelable(quoteStatus) ||
                            isNswScheme() ||
                            !actionPermissions.canCancelQuote
                        "
                        type="button"
                        mat-raised-button
                        color="warn"
                        (click)="cancelQuote()"
                    >
                        Withdraw Quote Record
                    </button>
                </div>
                <br />
                <div fxFlex="40%;" fxFlex.xs="80%">
                    <span *ngIf="isCrudViewMode()" fxLayout="row" fxLayoutGap="15px" fxLayoutGap.lt-md="5px">
                        <button
                            fxFlex="0 0 auto"
                            [disabled]="!isConfirmable() || !actionPermissions.canConfirmInstallation"
                            type="button"
                            mat-raised-button
                            color="primary"
                            (click)="confirmInstallation()"
                        >
                            Confirm Installation Completion
                        </button>
                        <mat-error *ngIf="!isConfirmable()">{{ getValidationErrorMessageForViewMode() }}</mat-error>
                    </span>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>
